import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./overview.module.css"
import logo from "./logo.png";
import Locations from "../locations/Locations";
import { IoClose, IoInformation, IoSearchSharp } from "react-icons/io5";
import { FirebaseAppContext } from "../../context/FirebaseApp";
import { getAnalytics, logEvent } from "firebase/analytics";

const Episodes = ({ episodes, locations, onLocationSelect, images, searchTerm }) => {
  if (!episodes || episodes.length === 0 || !locations || locations.length === 0) return null;
  return (
    <ul>
      {episodes.filter(episode => {
        const filteredLocations = locations.filter(location => location.Episodes.includes(episode));

        if (filteredLocations.length > 0 && episode.toLowerCase().includes(searchTerm.toLowerCase())) return true;
        else return false;
      }).map((episode, index) => {
        return (
          <li className={styles.episode} key={index}>
            <div className={styles.episodeName}>{episode}</div>
            <Locations 
              onLocationSelect={onLocationSelect} 
              locations={locations.filter(location => location.Episodes.includes(episode))}
              images={images}
            />
          </li>
        )
      })}
    </ul>
  )
}

const Info = () => {
  const [ open, setOpen ] = useState(false);
  const infoContainerRef = useRef(null);
  const infoButtonRef = useRef(null);

  useEffect(() => {
    const detectOutsideClick = (event) => {
      if (infoButtonRef.current && infoButtonRef.current.contains(event.target)) {
        setOpen(!open)
      } else if (open && infoContainerRef.current && !infoContainerRef.current.contains(event.target)) {
        setOpen(false)
      } 
    }
    const bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.addEventListener("click", detectOutsideClick);

    return () => bodyElement.removeEventListener("click", detectOutsideClick);
  }, [ open ]);

  return (
    <div className={styles.infoWrapper}>
      <div
        className={classNames(styles.infoContainer, {
          [styles.infoContainerOpen]: open,
        })}
        ref={infoContainerRef}
      >
        <div
          className={classNames(styles.infoText, {
            [styles.infoTextOpen]: open,
          })}
        >
          Site developed by{" "}
          <a href="https://avastec.com/" target="_blank" rel="noreferrer">
            Avastec.com
          </a>{" "}
          and{" "}
          <a
            href="http://sherlockpodcast.com/"
            target="_blank"
            rel="noreferrer"
          >
            Sherlockpodast.com
          </a>
        </div>
        <div>
          <button 
            className={classNames(styles.infoButton, {
              [styles.infoButtonOpen]: open,
            })}
            ref={infoButtonRef}
            aria-label={open ? "Hide Site About Information" : "Show Site About Information"}
          >
            {open ? (
              <IoClose className={styles.infoIcon} />
            ) : (
              <IoInformation className={styles.infoIcon} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default function Overview({ episodes, locations, selectedLocationId, onLocationSelect, images }){
  const [ grouped, setGrouped ] = useState(true);
  const [ searchTerm, setSearchTerm ] = useState("");
  const listContainerRef = useRef(null);
  const handlefilterButtonClick = (group) => {
    setGrouped(group);
    listContainerRef.current.scrollTop = 0
  };
  const FirebaseApp = useContext(FirebaseAppContext);
  const analytics = getAnalytics(FirebaseApp);

  const filterLocations = () => {
    return locations.filter(l => {
      return (
        l.Name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        l.TownCity?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        l.County?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        searchTerm === "" ||
        (!grouped && l.Episodes.join(" ").toLowerCase().includes(searchTerm.toLowerCase()))
      )
    })
  }

  useEffect(() => {
    if (!selectedLocationId) {
      console.log(`${grouped ? "Episodes" : "Locations"} List`);
      logEvent(analytics, "screen_view", {
        screen_name: `${grouped ? "Episodes" : "Locations"} List`,
        firebase_screen: `${grouped ? "Episodes" : "Locations"} List`,
      });
    }
  }, [ grouped, selectedLocationId, analytics ]);

  return (
    <div className={styles.overview}>
      <header className={styles.header}>
        <img className={styles.logo} src={logo} alt="Sherlock" width="250" height="252" />
        <h1 className={styles.title}>Filming locations of Jeremy Brett's Sherlock Holmes and where to find them</h1>
        <Info />
      </header>
      <div className={styles.results} ref={listContainerRef}>
        <div className={styles.filterContainer}>
          <div className={styles.filterButtonContainer}>
            <button 
              className={
                classNames(styles.filterButton, { [styles.filterButtonActive]: grouped })
              } 
              onClick={() => {
                handlefilterButtonClick(true)
                setSearchTerm("")
              }}
            >
              Episodes
            </button>
            <button 
              className={
                classNames(styles.filterButton, { [styles.filterButtonActive]: !grouped })
              }  
              onClick={() => {
                handlefilterButtonClick(false)
                setSearchTerm("")
              }}
            >
              Locations
            </button>
          </div>
          <div className={styles.searchBarContainer}>
            <IoSearchSharp className={styles.searchBarIcon} />
            <input 
              className={styles.searchBar}
              type="text" 
              placeholder={grouped ? "Filter by episode…" : "Search…"}
              value={searchTerm} 
              onChange={event => setSearchTerm(event.target.value)} 
            />
          </div>
        </div>
        <div className={styles.listContainer}>
          {
            grouped ? (
              <Episodes 
                episodes={episodes} 
                locations={locations} 
                onLocationSelect={onLocationSelect}
                images={images}
                searchTerm={searchTerm}
              />
            ) : (
              <Locations 
                onLocationSelect={onLocationSelect} 
                locations={filterLocations()} 
                groupedByEpisode={grouped}
                images={images}
                nestedLocation={false}
              />
            )
          }
        </div>
      </div>
    </div>
  );
}