import React, { useEffect, useState } from "react";
import styles from "./content.module.css"
import classNames from "classnames";
import { AiOutlineCaretLeft, AiFillCamera } from "react-icons/ai";
import Overview from "../overview/Overview";
import Details from "../details/Details";
import { useSwipeable } from "react-swipeable";
import Sidebar from "../sidebar/Sidebar";

export default function Content({ episodes, locations, selectedLocationId, onSelectedLocationChange, onChildLocationSelect, images }){  
  useEffect(() => {
    if (selectedLocationId) {
      setCollapseAll(false);
      setDetailsCollapsed(false)

    }
  }, [ selectedLocationId ])

  const [ overviewCollapsed, setOverviewCollapsed ] = useState(false);
  const [ detailsCollapsed, setDetailsCollapsed ] = useState(false);
  const [ collapseAll, setCollapseAll ] = useState(false);

  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === "Right") {
        setCollapseAll(false);
      } else if (eventData.dir === "Left") {
        setCollapseAll(true);
      }
    },
  })

  const handleToggleCollapseAll = () => {
    setCollapseAll(!collapseAll);
  }

  return (
    <div
      {...swipeHandlers}
      className={classNames(styles.sidebarContainer, { [styles.collapsed]: collapseAll })}
    >
      <Sidebar onCollapsed={setOverviewCollapsed} collapsed={overviewCollapsed}>
        <Overview 
          episodes={episodes} 
          locations={locations} 
          onLocationSelect={onSelectedLocationChange}
          images={images}
          selectedLocationId={selectedLocationId}
        />
      </Sidebar>
      {
        selectedLocationId ? (
          <Sidebar onCollapsed={setDetailsCollapsed} collapsed={detailsCollapsed}>
            <Details 
              onGoBack={() => onSelectedLocationChange(null)}
              location={locations.find(location => location.id === selectedLocationId)}
              images={images}
              onLocationSelect={onSelectedLocationChange}
              onChildLocationSelect={onChildLocationSelect}
            />
          </Sidebar>
        ) : null
      }
      <button aria-label="Toggle Sidebar" className={styles.toggleButton} onClick={() => handleToggleCollapseAll()}>
        <AiOutlineCaretLeft className={styles.toggleIcon} />
      </button>
    </div>
  )
}