import React, { useEffect, useRef, useContext } from "react";
import Leaflet from "leaflet";
import Image from "../image/Image";
import { Marker, ZoomControl, TileLayer, MapContainer, useMap, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./map.module.css";
import "./leaflet-extends.css"
import limitDescription from "../../utils/limitDescription";
import { getFeaturedImage } from "../../utils/imagePriorities";
import { FirebaseAppContext } from "../../context/FirebaseApp";
import { getAnalytics, logEvent } from "firebase/analytics";

function ZoomInToLocation ({ selectedLocationId, locations, paddingTopLeft }) {
  const location = locations.find(l => l.id === selectedLocationId);
  const map = useMap();
  const bounds = [
    location,
    ...(location.childLocations ? location.childLocations : [])
  ].map(location => {
    const { latitude, longitude } = location.Location;
    return [latitude, longitude];
  });
  map.fitBounds(bounds, { paddingTopLeft })
  return null;
}

function ZoomOutToLocations ({ bounds, paddingTopLeft }) {
  const map = useMap();
  map.fitBounds(bounds, { paddingTopLeft })
  return null;
}

function CustomMarker ({ location, isChildLocation = false, imageSrc, onSelectedLocationChange, selectedLocationId }) {
  const { latitude, longitude } = location.Location;
  const markerRef = useRef(null);
  const FirebaseApp = useContext(FirebaseAppContext);
  const analytics = getAnalytics(FirebaseApp);

  useEffect(() => {
    markerRef.current.closePopup()
  }, [ selectedLocationId, location.id ]);

  return (
    <Marker
      autoPan={false}
      ref={markerRef}
      eventHandlers={{
        click: () => {
          logEvent(analytics, "marker_clicked", {
            location_name: location.Name
          });
        }
      }}
      className={selectedLocationId === location.id ? "selected" : ""}
      {...(isChildLocation ? {
        icon: new Leaflet.icon({
          iconUrl: "childLocationMarker.svg",
          iconSize: [16, 21],
          iconAnchor: [8, 21],
          shadowSize: [0, 0],
          className: selectedLocationId === location.id ? "bounce" : ""
        })
      } : {
        icon: new Leaflet.icon({
          iconUrl: selectedLocationId === location.id ? "locationMarkerSelected.svg" : "locationMarker.svg",
          iconSize: [25, 33],
          iconAnchor: [12.5, 33]
        })
      })}
      
      
      position={[ latitude, longitude ]}
    >
      <Popup autoPan={false} eventHandlers={{
        click: () => console.log("f")
      }}>
        <div className={styles.popupInner}>
          <Image aspectRatio="3/1" src={imageSrc} type="medium" />
          <div className={styles.popupContent}>
            { isChildLocation ? (
              <>
                <div className={styles.popupTitle}>{location.Name}</div>
                {/* <div className={styles.popupSubTitle}>{selectedLocation.Name}</div> */}
                <div className={styles.popupText} dangerouslySetInnerHTML={{ __html: limitDescription(location.Description)}}></div>
              </>
            ) : (
              <>
                <div className={styles.popupTitle}>{location.Name}</div>
                <ul className={styles.popupEpisodes}>
                  {location.Episodes.map((episode, index) => {
                    return (
                      <li key={index}>{episode}</li>
                    )
                  })}
                </ul>
                <div className={styles.popupLinkContainer}>
                  <button className="link" onClick={() => onSelectedLocationChange(location.id)}>View Details</button>
                </div>
              </>
            )}
          </div>
        </div>
      </Popup>
    </Marker>
  )
}

export default function Map({ locations, selectedLocationId, selectedChildLocationId, onSelectedLocationChange, images }){
  const bounds = locations.map(location => {
    const { latitude, longitude } = location.Location;
    return [latitude, longitude];
  });

  if (bounds.length === 0 || !locations) return null;

  const selectedLocation = locations.find(l => l.id === selectedLocationId);
  const windowWidth = window.innerWidth;
  const SIDEBAR_WIDTH = 700;
  const paddingTopLeft = [
    windowWidth > SIDEBAR_WIDTH ? SIDEBAR_WIDTH : 0, 
    0
  ];

  return (
    <MapContainer 
      className={styles.mapContainer} 
      bounds={bounds}
      boundsOptions={{ 
        paddingTopLeft 
      }}
      zoomControl={false}
    >
      {selectedLocationId ? (
        !selectedChildLocationId ? (
          <ZoomInToLocation 
            selectedLocationId={selectedLocationId} 
            locations={locations}  
            paddingTopLeft={paddingTopLeft}
          />
        ) : null
      ) : (
        <ZoomOutToLocations bounds={bounds} paddingTopLeft={paddingTopLeft} />
      )}
      <ZoomControl position="bottomright" />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {locations.map(location => {
        const featuredImage = getFeaturedImage({ 
          locationImages: images.filter(image => image.Location === location.id),
          childLocationImages: images.filter(image => {
            return location.childLocations?.map(l => l.id).includes(image.Location);
          }) 
        });

        return (
          <CustomMarker
            key={location.id}
            imageSrc={featuredImage}
            location={location}
            onSelectedLocationChange={onSelectedLocationChange}
            selectedLocationId={selectedLocationId}
          />
        )
      })}
      {selectedLocation && selectedLocation.childLocations?.map((location, index) => {
        const featuredImage = getFeaturedImage({ 
          locationImages: images.filter(image => image.Location === location.id)
        });

        return (
          <CustomMarker
            key={location.id}
            isChildLocation={true}
            imageSrc={featuredImage}
            location={location}
            selectedLocationId={selectedChildLocationId}
          />
        )
      })}
    </MapContainer>
  );
}