const filterPriorities = (images) => {
  return images.filter(image => {
    return image.Priority && image.Priority > 0
  });
};

const getLowestPriorityValue = (images = []) => {
  const filteredPriorities = filterPriorities(images)
  if (filteredPriorities.length === 0) return;
  
  return filteredPriorities.reduce((prev, curr) => {
    return prev.Priority < curr.Priority ? prev : curr;
  });
};

const sortPriorities = (images = []) => {
  const filteredPriorities = filterPriorities(images)
  if (filteredPriorities.length === 0) return [];

  return filteredPriorities.sort((prev, curr) => {
    if (prev.Priority < curr.Priority) {
      return -1;
    } else if (prev.Priority > curr.Priority) {
      return 1;
    } else {
      return 0;
    }
  });
}

const getFeaturedImage = ({ locationImages = [], childLocationImages = [] }) => {
  if (!locationImages || locationImages.length === 0) return "";
  
  const orderedLocationImages = getLowestPriorityValue(locationImages);
  const orderedChildLocationImages = getLowestPriorityValue(childLocationImages);

  if (orderedLocationImages) {
    return orderedLocationImages.Path;
  } else if (orderedChildLocationImages) {
    return orderedChildLocationImages.Path
  } else {
    return "";
  }
};

const getOrderedImages = ({ locationImages = [], childLocationImages = [] }) => {
  const sortedLocationImages = sortPriorities(locationImages);
  const sortedChildLocationImages = sortPriorities(childLocationImages);

  return [
    ...sortedLocationImages,
    ...sortedChildLocationImages
  ]
};

export { getFeaturedImage, getOrderedImages };
