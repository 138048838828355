import React, {  useState } from "react";
import styles from "./sidebar.module.css"
import classNames from "classnames";
import { AiOutlineCaretLeft } from "react-icons/ai";
import { useSwipeable } from "react-swipeable";

export default function Sidebar({ children }){

  return (
    <div className={styles.sidebar}>
      {children}
    </div>
  );
}