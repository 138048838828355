import React, { useEffect, useState, useContext } from "react";
import styles from "./gallery.module.css"
import Image from "../image/Image";
import { AiFillCaretLeft, AiFillCaretRight, AiFillCaretUp } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import { ReactComponent as RotateIcon } from "./rotate-device.svg"
import classNames from "classnames";
import { FirebaseAppContext } from "../../context/FirebaseApp";
import { getAnalytics, logEvent } from "firebase/analytics";

function Thumbs({ title, locationImageUrls, onGoBack, onSelectImage, selectedImageIndex }) {
  return (
    <div className={styles.thumbs}>
      <div className={styles.thumbsHeader}>
        <div className={styles.thumbsNav}>
          <button className="link" onClick={onGoBack}>
            <AiFillCaretLeft className="link-icon" />
            Back to details
          </button>
        </div>
        <h2 className={styles.thumbsTitle}>{title}</h2>
      </div>
      {locationImageUrls.map((imageUrl, index) => {
        return (
          <div 
            className={
              classNames(
                styles.thumbImageContainer, {
                [styles.thumbImageContainerActive]: selectedImageIndex === index
              })
            } 
            key={index} 
            onClick={() => onSelectImage(index)}
          >
            <Image src={imageUrl} type="medium" />
          </div>
        )
      })}
    </div>
  )
}

function Detail({ url, title, description, onPrevPhoto, onNextPhoto, onBackToGalleryList }) {
  const [ fullScreen, setFullScreen ] = useState(false)

  return (
    <figure 
      className={[
        styles.detail,
        (fullScreen ? styles.detailFullScreen : null)
      ].join(" ")} 
    >
      <div 
        className={[
          styles.detailImageContainer,
          (fullScreen ? styles.detailImageContainerFullScreen : null)
        ].join(" ")} 
      >
        <Image fullScreen={fullScreen} className={styles.detailImage} src={url} alt="" type="large" dynamicObjectFit={true} />
        <button 
          className={[
            "link",
            styles.detailImageBack,
          ].join(" ")} 
          onClick={onBackToGalleryList}
        >
          <AiFillCaretUp className="link-icon" />
          <div>Back to Images</div>
        </button>
        {fullScreen ? (
          <button 
            className={styles.detailImageClose} 
            onClick={() => setFullScreen(false)}
          >
            <IoIosClose className={styles.detailImageCloseIcon}  />
          </button>
        ) : null}
        <button className={styles.detailImageArrow} onClick={onPrevPhoto}>
          <AiFillCaretLeft className="link-icon" />
        </button>
        <button className={styles.detailImageArrow} onClick={onNextPhoto}>
          <AiFillCaretRight className="link-icon" />
        </button>
      </div>
      { !fullScreen ? (
        <figcaption className={styles.detailFigcaption}>
          <div className={styles.detailFigcaptionInner}>
            <div className={styles.detailText}>
              <h2 className={styles.detailTitle}>{title}</h2>
              {description ? (
                <div className={styles.detailDescription} dangerouslySetInnerHTML={{ __html: description }}></div> 
              ) : null}
            </div>
            <div className={styles.links}>
              <button 
                className={[
                  styles.detailFigcaptionFullScreenButton, 
                  "link"
                ].join(" ")}
                onClick={() => setFullScreen(true)}
              >
                View Gallery Full Screen
              </button>
              <div 
                className={styles.detailFigcaptionFullScreen} 
              >
                <RotateIcon className="link-icon" />
                <div>Rotate device to see images fullscreen</div>
              </div>
            </div>
          </div>
        </figcaption>
      ) : null}
    </figure>
  )
}

export default function Gallery({ images, onCloseGallery, locationId, title, onPrevPhoto, onNextPhoto }){
  const [ selectedImageIndex, setSelectedImageIndex ] = useState(-1);
  const FirebaseApp = useContext(FirebaseAppContext);
  const analytics = getAnalytics(FirebaseApp);

  const handlePrevPhoto = () => {
    setSelectedImageIndex(
      selectedImageIndex > 0 ? selectedImageIndex - 1 : selectedImageIndex
    );
  };
  const handleNextPhoto = () => {
    setSelectedImageIndex(
      selectedImageIndex < images.length - 1 ? selectedImageIndex + 1 : selectedImageIndex
    );
  }

  useEffect(() => {
    console.log(`Location Gallery: ${title}`);
    logEvent(analytics, "screen_view", {
      screen_name: `Location Gallery: ${title}`,
      firebase_screen: `Location Gallery: ${title}`,
    });
  }, [ title, analytics ]);

  return (
    <>
      {selectedImageIndex > -1 ? (
        <Detail 
          title={images[selectedImageIndex].Name}
          description={images[selectedImageIndex].Description}
          url={images[selectedImageIndex].Path} 
          onCloseDetail={onCloseGallery}
          onPrevPhoto={handlePrevPhoto}
          onNextPhoto={handleNextPhoto}
          onBackToGalleryList={() => setSelectedImageIndex(-1)}
        />
      ) : null}
      <Thumbs 
        locationImageUrls={images.map(i => i.Path)} 
        onGoBack={onCloseGallery} 
        locationId={locationId}
        onSelectImage={setSelectedImageIndex}
        selectedImageIndex={selectedImageIndex}
        title={title}
      />
    </>
  );
}