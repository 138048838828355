import React, { useEffect, useState, useContext } from "react";
import styles from "./details.module.css";
import { MdOutlinePhotoLibrary } from "react-icons/md";
import { AiFillCaretLeft } from "react-icons/ai";
import { HiExternalLink } from "react-icons/hi";
import Image from "../image/Image";
import Gallery from "../gallery/Gallery";
import Locations from "../locations/Locations";
import { getFeaturedImage, getOrderedImages } from "../../utils/imagePriorities";
import { FirebaseAppContext } from "../../context/FirebaseApp";
import { getAnalytics, logEvent } from "firebase/analytics";

function List ({ title, lines = [], footer }) {
  return (
    <div className={styles.listContainer}>
      <h2 className={styles.listTitle}>{title}</h2>
      <ul className={styles.list}>
        {lines.filter(line => line).map((line, index) => {
          return (
            <li key={index}>{line}</li>
          )
        })}
      </ul>
      {footer ? <div className={styles.listFooter}>{footer}</div> : null}
    </div>
  )
}

function AreasOfInterest ({ childLocations, images, onChildLocationSelect }) {
  return (
    <div className={styles.areasOfInterest}>
      <h2 className={styles.areasOfInterestTitle}>Areas of Interest</h2>
      <Locations 
        onLocationSelect={onChildLocationSelect} 
        locations={childLocations}
        images={images}
        childLocation={true}
      />
    </div>
  )
}

export default function Details({ onGoBack, location, images, onChildLocationSelect }){
  const [ galleryOpen, setGalleryOpen ] = useState(false);
  const { latitude, longitude } = location.Location;
  const FirebaseApp = useContext(FirebaseAppContext);
  const analytics = getAnalytics(FirebaseApp);

  const locationImages = images.filter(image => {
    return image.Location === location.id;
  });
  const childLocationImages = images.filter(image => {
    return location.childLocations?.map(l => l.id).includes(image.Location);
  });
  const orderedImages = getOrderedImages({ locationImages, childLocationImages });
  const featuredImage = getFeaturedImage({ locationImages, childLocationImages });

  useEffect(() => {
    if(!galleryOpen) {
      console.log(`Location Details: ${location.Name}`)
      logEvent(analytics, "screen_view", {
        screen_name: `Location Details: ${location.Name}`,
        firebase_screen: `Location Details: ${location.Name}`
      });
    }
  }, [ location.Name, galleryOpen, analytics ]);

  return (
    galleryOpen ? (
      <Gallery
        images={getOrderedImages({ locationImages, childLocationImages })}
        onCloseGallery={() => setGalleryOpen(false)} 
        locationId={location.id}
        title={location.Name}
      />
    ) : (
      <div className={styles.detailsContainer}>
        <header className={styles.header}>
          <div 
            className={styles.headerImageContainer} 
            onClick={() => {
              if (orderedImages.length > 0) setGalleryOpen(true)
            }}
          >
            <Image src={featuredImage} alt={location.Name} aspectRatio={16/5} type="medium" />
            <div className={styles.galleryLink}>
              <MdOutlinePhotoLibrary className={styles.galleryLinkIcon} />
              <span>{orderedImages.length} Photo{orderedImages.length === 1 ? "" : "s"}</span>
            </div>
          </div>
        </header>
        <div className={styles.nav}>
          <button className="link" onClick={onGoBack}>
            <AiFillCaretLeft className="link-icon" />
            Back to list
          </button>
        </div>
        <div className={styles.content}>
          <h1 className={styles.locationName}>{location.Name}</h1>
          <div className={styles.locationDescription} dangerouslySetInnerHTML={{ __html: location.Description }}></div>
          <div className={styles.lists}>
            <List
              title="Address"
              lines={[
                location.AddressLine1,
                location.AddressLine2,
                location.TownCity,
                location.County,
                location.Postcode
              ]}
              footer={
                <a 
                  href={`https://maps.google.com/?q=${latitude},${longitude}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <a 
                    href={`https://maps.apple.com/?q=${latitude},${longitude}`}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    View on Maps
                    <HiExternalLink className={styles.mapLink} />
                  </a>
                </a>
              }
            />
            <List
              title="Episodes"
              lines={location.Episodes}
            />
          </div>
          {location.childLocations && location.childLocations.length > 0 ? (
            <AreasOfInterest 
              childLocations={location.childLocations} 
              images={images}
              onChildLocationSelect={onChildLocationSelect}
            />
          ) : null}
        </div>
      </div>
    )
  );
}