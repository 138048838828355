import React from "react";
import styles from "./locations.module.css"
import Image from "../image/Image";
import classNames from "classnames";
import { getFeaturedImage } from "../../utils/imagePriorities";
import limitDescription from "../../utils/limitDescription";

const LocationFeaturedImage = ({ imageUrl, alt }) => {
  return (
    <Image aspectRatio="1/1" src={imageUrl} alt={alt} type="small" />
  )
}

export default function Locations ({ locations, onLocationSelect, groupedByEpisode, images, nestedLocation = true, childLocation = false }) {
  return (
    <ul>
      {locations.map((location) => {
        const featuredImage = getFeaturedImage({ 
          locationImages: images.filter(image => image.Location === location.id),
          childLocationImages: images.filter(image => {
            return location.childLocations?.map(l => l.id).includes(image.Location);
          }) 
        });

        return (
          <li 
            onClick={() => onLocationSelect(location.id)} 
            className={classNames(styles.location, { [styles.nestedLocation]: nestedLocation })} 
            key={location.id}
          >
            {
              childLocation ? (
                <img className={styles.markerIcon} src="/childLocationMarker.svg" alt="Marker" width="20" height="27" /> 
              ) : null
            }
            <div className={styles.locationText}>
              <div className={styles.locationName}>{location.Name}</div>
              <div className={styles.locationDescription} dangerouslySetInnerHTML={{ __html: limitDescription(location.Description)}}></div>
              {!groupedByEpisode ? (
                <ul className={styles.locationEpisodeList}>
                  {location.Episodes.map((episode, index) => {
                    return <li className={styles.locationEpisodeListItem} key={index}>{episode}</li>
                  })}
                </ul>
              ) : null}
            </div>
            <div className={styles.locationImageContainer}>
              <LocationFeaturedImage 
                imageUrl={featuredImage || ""} 
                alt={location.Name} 
              />
            </div>
          </li>
        )
      })}
    </ul>
  )
}