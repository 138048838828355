import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, orderBy, query, where } from "firebase/firestore/lite"; 
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { FirebaseAppContext } from "./context/FirebaseApp";

import Map from "./components/map/Map";
import './App.css';
import Content from "./components/content/Content";
import { useEffect, useState } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyALxoEgz4t2YFkezRm43IewpJ5eFHcKHsQ",
  authDomain: "sherlock-38bc7.firebaseapp.com",
  projectId: "sherlock-38bc7",
  storageBucket: "sherlock-38bc7.appspot.com",
  messagingSenderId: "350224905626",
  appId: "1:350224905626:web:82c338e73556458609b623",
  measurementId: "G-TXGHNY75EX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


function App() {
  const [ locations, setLocations ] = useState([]);
  const [ episodes, setEpisodes ] = useState([]);
  const [ images, setImages ] = useState([]);
  const [ selectedLocationId, setSelectedLocationId ] = useState(null);
  const [ selectedChildLocationId, setSelectedChildLocationId ] = useState(null);

  const mergeChildLocations = (locations) => {
    return locations.map(location => {
      const childLocations = locations.filter(l => l.Published && l.ParentId === location.id);
      if (childLocations.length > 0) {
        return {
          ...location,
          childLocations
        }
      } else {
        return location;
      }
    }).filter(l => !l.ParentId || l.ParentId.length === 0)
  }

  useEffect(() => {
    (async() => {
      try {
        const ref = collection(db, "Locations");
        const querySnapshot = await getDocs(query(ref, orderBy("Name")));
        const locationData = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        })).filter(l => l.Published);

        setLocations(
          mergeChildLocations(locationData)
        );
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async() => {
      try {
        const ref = collection(db, "Images");
        const querySnapshot = await getDocs(ref);
        const imagesData = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id
        }));

        setImages(imagesData);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    const allEpisodes = locations.flatMap(location => location.Episodes);
    setEpisodes(
      [...new Set(allEpisodes)].sort((a, b) => {
        return a.localeCompare(b)
      })
    )
  }, [ locations ]);

  const handleSelectedLocationChange = (id) => {
    console.log('location changed: ' + id)
    setSelectedChildLocationId(null);
    setSelectedLocationId(id);
  }
  
  return (
    <FirebaseAppContext.Provider value={app}>
      <div className="App">
        <Map 
          locations={locations} 
          selectedLocationId={selectedLocationId} 
          selectedChildLocationId={selectedChildLocationId}
          onSelectedLocationChange={(id) => handleSelectedLocationChange(id)} 
          images={images}
        />
        <Content 
          episodes={episodes} 
          locations={locations} 
          selectedLocationId={selectedLocationId} 
          onSelectedLocationChange={(id) => handleSelectedLocationChange(id)}
          onChildLocationSelect={setSelectedChildLocationId}
          images={images}
        />
      </div>
    </FirebaseAppContext.Provider>
  );
}

export default App;
